import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyleFooter = styled.footer`
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px 16px 12px 16px;
  display: flex;
  justify-content: start;
  flex-direction: row;
  border-top: 1px solid var(--ui-border-primary-grey-200, #e1e6eb);
  background: var(--ui-bg-primary-grey-0, #fff);
  box-shadow: 0px -12px 32px -20px rgba(135, 147, 161, 0.25),
    0px -4px 8px -8px rgba(135, 147, 161, 0.5);
`;

interface FooterButtonsProps {
  children?: ReactNode;
}

const FooterButtons: React.FC<FooterButtonsProps> = ({ children }) => {
  const widthPercent = 95.0 / React.Children.count(children);
  const styledChildren = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        style: {
          ...child.props.style, // Preserve original styles
          width: `${widthPercent}%`,
          maxWidth: '1000000px', // Mastodon button is providing a max-width
          justifyContent: 'center',
        },
      });
    }
    return child;
  });

  return <StyleFooter data-testid='fixed-footer'>{styledChildren}</StyleFooter>;
};

export default FooterButtons;
