import React from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useFlagr } from 'api/flagr';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import MainContainer from '../../components/MainContainer';
import Label from '../../components/fields/Label';
import { FLAGR_KEYS } from '../../constants';
import { Icon } from './Icon';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Paragraph, Alignment as ParagraphAlignment } from './Paragraph';
import { TitledContainer } from './TitledContainer';
import { UnorderedList } from './UnorderedList';
import { BulletedItem } from './BulletedItem';
import { Greeting } from './Greeting';
import { SecureDisclosure } from './SecureDisclosure';

export const InstantExceptionsDebug: React.FC = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const reportId = params?.reportId?.replace(/[^a-fA-F0-9]/g, '').slice(0, 24);
  const { flag, isFetched: flagIsLoaded } = useFlagr({
    context: {
      locationHost: window.location.host,
    },
    id: FLAGR_KEYS.instantExceptionsEnabled,
    entity: { id: reportId, type: 'report' },
    fallbackVariant: 'off',
  });
  const { variantKey: instantExceptionsEnabled } = flag;

  if (flagIsLoaded && instantExceptionsEnabled !== 'on') {
    return <Navigate to='/' />;
  }

  const company = searchParams.get('company') || '';
  const firstName = searchParams.get('firstName') || '';

  return (
    <MainContainer style={{ background: colors.uiGrey50 }}>
      {flagIsLoaded ? (
        <M.Grid>
          <M.GridRow style={{ flexDirection: 'column' }}>
            <Greeting company={company} firstName={firstName} />
            <StyleableContainer styledTopBorder>
              <p>...placeholder content...</p>
            </StyleableContainer>
          </M.GridRow>
          <M.GridRow>
            <SecureDisclosure />
          </M.GridRow>
          <M.GridRow>
            <M.GridCol>
              <Label
                label={i18n.getStr(
                  'components.InstantExceptions.loading',
                  reportId,
                )}
              />
            </M.GridCol>
          </M.GridRow>
          <M.GridRow>
            <M.GridCol>
              <p className='mastodon p1 bold'>Default icon</p>
              <Icon />
            </M.GridCol>
            <M.GridCol>
              <p>Info icon</p>
              <Icon type={Type.Info} />
            </M.GridCol>
            <M.GridCol>
              <p>Success Icon</p>
              <Icon type={Type.Success} />
            </M.GridCol>
            <M.GridCol>
              <p>Fail Icon</p>
              <Icon type={Type.Fail} />
            </M.GridCol>
          </M.GridRow>
          <M.GridRow>
            <M.GridCol>
              <StyleableContainer styledTopBorder>
                <img
                  style={{ background: 'blue' }}
                  className='header-logo'
                  src='https://assets.checkr.com/logo-white-2022.svg'
                  alt={i18n.getStr('components.Header.checkrLogo')}
                />
                <p>Waiting Container</p>
              </StyleableContainer>
            </M.GridCol>
            <M.GridCol>
              <StyleableContainer styledTopBorder type={Type.Info}>
                <p>Info Container</p>
              </StyleableContainer>
            </M.GridCol>
            <M.GridCol>
              <StyleableContainer styledTopBorder type={Type.Success}>
                <p>Success Container</p>
              </StyleableContainer>
            </M.GridCol>
            <M.GridCol>
              <StyleableContainer styledTopBorder type={Type.Fail}>
                <p>Fail Container</p>
              </StyleableContainer>
            </M.GridCol>
          </M.GridRow>
          <M.GridRow>
            <StyleableContainer
              type={Type.Success}
              styledTopBorder
              style={{ border: '8px solid' }}
            >
              <p>Custom styled container</p>
            </StyleableContainer>
          </M.GridRow>
          <M.GridRow>
            <M.Container>
              <Heading textKey='Left, Pardner!' align={HeadingAlignment.Left} />
              <Heading
                textKey='Center, Pardner!'
                align={HeadingAlignment.Center}
              />
              <Heading
                textKey='Right, Pardner!'
                align={HeadingAlignment.Right}
              />
            </M.Container>
          </M.GridRow>
          <M.GridRow>
            <M.Container>
              <Paragraph
                textKey='Left aligned, good buddy!'
                align={ParagraphAlignment.Left}
              />
              <Paragraph
                textKey='Center aligned, good buddy!'
                align={ParagraphAlignment.Center}
              />
              <Paragraph
                textKey='Right aligned, good buddy!'
                align={ParagraphAlignment.Right}
              />
            </M.Container>
          </M.GridRow>
          <M.GridRow>
            <TitledContainer titleKey='Little Bo Peep'>
              <p className='p3'>
                Has lost her sheep, and doesn&apos;t know where to find them!
                <br />
                Leave them alone, and they&apos;ll come home <br />
                Wagging their tails behind them. <br />
              </p>
            </TitledContainer>
          </M.GridRow>
          <M.GridRow>
            <UnorderedList
              textKeys={[
                'Little Miss Muffet',
                'Sat on her tuffet',
                'eating her curds and whey.',
                'Along came a spider',
                'and sat down beside her',
                'and frightened Miss Muffet away',
              ]}
            />
          </M.GridRow>
          <M.GridRow>
            <M.GridCol
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <BulletedItem arrowIcon text='lorem ipsum dolor sit amet' />
              <BulletedItem text='quo dulce et decorum est pro patria mori' />
            </M.GridCol>
          </M.GridRow>
          <M.GridRow>
            <M.Container>
              <M.Button>{i18n.getStr('buttons.submit')}</M.Button>
              <M.Button kind='secondary'>
                {i18n.getStr('buttons.addLater')}
              </M.Button>
            </M.Container>
          </M.GridRow>
        </M.Grid>
      ) : null}
    </MainContainer>
  );
};
