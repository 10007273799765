import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

export const SecureDisclosure: React.FC = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
    }}
  >
    <M.Icon
      icon='Locked'
      size={16}
      style={{ margin: '8px', fill: colors.uiGray400 }}
    />
    <p className='p4' style={{ color: colors.uiTextTertiaryLight }}>
      {i18n.getStr('components.InstantExceptions.secureDisclosure')}
    </p>
  </div>
);
