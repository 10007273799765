import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Greeting } from './Greeting';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { TitledContainer } from './TitledContainer';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { BulletedItem } from './BulletedItem';
import { Icon } from './Icon';
import { Paragraph } from './Paragraph';
import FooterButtons from './FooterButtons';

interface UploadDocumentLaterProps {
  company?: string;
  firstName?: string;
}

export const UploadDocumentLater: React.FC<UploadDocumentLaterProps> = ({
  company = '',
  firstName = '',
}) => {
  return (
    <M.Container>
      <Greeting company={company} firstName={firstName} />
      <StyleableContainer type={Type.Info} styledTopBorder>
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.UploadDocumentLater.heading'
          align={HeadingAlignment.Center}
        />
        <Paragraph
          textKey='components.InstantExceptions.UploadDocumentLater.detail'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%' }} />
        <TitledContainer titleKey='components.InstantExceptions.StartedYourBackgroundCheck.whatNext'>
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.UploadDocumentLater.contactYou'
          />
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.StartedYourBackgroundCheck.logIn'
          >
            <a href='https://candidate.checkr.com'>
              {i18n.getStr('components.Header.title')}
            </a>
            <span>
              {i18n.getStr(
                'components.InstantExceptions.UploadDocumentLater.toUpload',
              )}
            </span>
          </BulletedItem>
        </TitledContainer>
      </StyleableContainer>
      <FooterButtons>
        <M.Button>
          {i18n.getStr(
            'components.InstantExceptions.UploadDocumentLater.backToEdit',
          )}
        </M.Button>
      </FooterButtons>
    </M.Container>
  );
};
