import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

interface GreetingProps {
  company?: string;
  firstName?: string;
}

export const Greeting: React.FC<GreetingProps> = ({
  company = null,
  firstName = null,
}) => {
  return (
    <M.Container
      style={{
        border: 'none',
        // background: colors.uiGrey50,
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <h4 style={{ color: colors.uiTextPrimaryLight }}>
        {i18n.getStr('components.InstantExceptions.greeting.name', firstName)}
      </h4>
      <h5 style={{ color: colors.uiTextTertiaryLight }}>
        {i18n.getStr('components.InstantExceptions.greeting.company')}
        <span style={{ color: colors.uiTextPrimaryLight }}>{company}</span>
      </h5>
    </M.Container>
  );
};
