import React from 'react';
import { i18n } from '@international/mastodon-i18n';

interface HeadingProps {
  textKey: string;
  align?: Alignment;
}

export enum Alignment {
  Center = 1,
  Left,
  Right,
}

export const Heading: React.FC<HeadingProps> = ({ textKey, align }) => (
  <h4
    style={{
      textAlign:
        // eslint-disable-next-line no-nested-ternary
        align === Alignment.Center
          ? 'center'
          : align === Alignment.Right
          ? 'right'
          : 'left',
      width: '100%',
    }}
  >
    {i18n.getStr(textKey)}
  </h4>
);
