import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import { Greeting } from './Greeting';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { TitledContainer } from './TitledContainer';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Icon } from './Icon';
import { Paragraph, Alignment as ParagraphAlignment } from './Paragraph';
import { SecureDisclosure } from './SecureDisclosure';
import * as V from '../../lib/validations';
import * as N from '../../lib/normalizations';
import { SensitiveTextInput } from '../../components/fields/mastodon';
import FooterButtons from './FooterButtons';

interface SsnReentryProps {
  company?: string;
  firstName?: string;
}

export const SsnReentry: React.FC<SsnReentryProps> = ({
  company = '',
  firstName = '',
}) => {
  return (
    <M.Container>
      <Greeting company={company} firstName={firstName} />
      <StyleableContainer type={Type.Info} styledTopBorder>
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.SsnReentry.heading'
          align={HeadingAlignment.Center}
        />
        <Paragraph
          textKey='components.InstantExceptions.SsnReentry.detail'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%' }} />
        <TitledContainer titleKey='components.InstantExceptions.SsnReentry.reenterHeading'>
          <Paragraph
            align={ParagraphAlignment.Left}
            textKey='components.InstantExceptions.SsnReentry.reenterDetail'
          />
        </TitledContainer>
        <Field
          id='ssnInput'
          type='tel'
          name='ssn'
          placeholder='placeholders.ssn'
          className='ssn-reentry__input'
          labelControl={
            <>
              <span style={{ fontSize: '14px' }}>
                {i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.ssnInputLabel',
                )}
              </span>
              <M.Icon icon='Locked' />
            </>
          }
          component={SensitiveTextInput}
          normalize={N.ssn}
          validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
          mask={/\w/g}
          onPaste={(e: any) => e.preventDefault()}
          tooltip={{
            show: i18n.getStr(
              'components.Report.Verification.UpdatedSsnCheck.tooltip.show',
            ),
            hide: i18n.getStr(
              'components.Report.Verification.UpdatedSsnCheck.tooltip.hide',
            ),
          }}
        />
        <Field
          type='tel'
          name='ssnConfirmation'
          placeholder='placeholders.ssn'
          className='ssn-reentry__input'
          labelControl={
            <>
              <span style={{ fontSize: '14px' }}>
                {i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.confirmSsnInputLabel',
                )}
              </span>
              <M.Icon icon='Locked' />
            </>
          }
          component={SensitiveTextInput}
          normalize={N.ssn}
          validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
          mask={/\w/g}
          onPaste={(e: any) => e.preventDefault()}
          tooltip={{
            show: i18n.getStr(
              'components.Report.Verification.UpdatedSsnCheck.tooltip.show',
            ),
            hide: i18n.getStr(
              'components.Report.Verification.UpdatedSsnCheck.tooltip.hide',
            ),
          }}
        />
        <SecureDisclosure />
      </StyleableContainer>
      <StyleableContainer>
        <Heading
          textKey='components.InstantExceptions.SsnReentry.helpfulToKnow'
          align={HeadingAlignment.Left}
        />
        <M.UnorderedList>
          <M.ListItem>
            <Paragraph
              textKey='components.InstantExceptions.SsnReentry.weUse'
              align={ParagraphAlignment.Left}
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              textKey='components.InstantExceptions.SsnReentry.yourCredit'
              align={ParagraphAlignment.Left}
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              textKey='components.InstantExceptions.SsnReentry.weCant'
              align={ParagraphAlignment.Left}
            />
          </M.ListItem>
        </M.UnorderedList>
      </StyleableContainer>
      <FooterButtons>
        <M.Button type='submit'>
          {i18n.getStr('components.InstantExceptions.SsnReentry.submit')}
        </M.Button>
      </FooterButtons>
    </M.Container>
  );
};
