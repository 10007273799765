import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Panel from '../Panels';
import SsnCheckForm from './SsnCheckForm';
import ssnCard from '../../images/icons/ssn-card.svg';
import Verification, { connectToVerifications } from './VerificationBase';

class SsnCheckVerification extends Verification {
  renderCustomPanel() {
    const { doc } = this.getRenderAttributes();

    const {
      verification: { canDeclareNoSsn },
    } = this.props;

    return (
      <M.Grid>
        {doc.input && (
          <div data-testid='ssn-check'>
            <Panel
              i18n
              title='components.Report.Verification.SsnCheck.title'
              className='card-plain card-border'
            >
              <M.GridRow>
                <M.GridCol md={12} className='text-center panel-header'>
                  <img src={ssnCard} alt='ssn-card' />
                </M.GridCol>
              </M.GridRow>
              <SsnCheckForm canDeclareNoSsn={canDeclareNoSsn} />
            </Panel>
          </div>
        )}
      </M.Grid>
    );
  }
}

export default connectToVerifications(SsnCheckVerification);
