import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { isValid as reduxFormIsValid, WrappedFieldProps } from 'redux-form';

import FilestackUpload from '../../FilestackUpload/FilestackUpload';
import {
  VERIFICATION_FORM_NAME,
  DISPUTE_FORM_NAME,
  CANDIDATE_STORY_FORM_NAME,
  INSTANT_EXCEPTIONS_FORM_NAME,
} from '../../../constants';

interface UploadFieldAdapterProps extends WrappedFieldProps {
  options: any;
  buttonText?: string;
  labelText: string;
  disabled?: boolean;
  onChange?: (...args: any[]) => void;
}

const UploadFieldAdapter: React.FC<UploadFieldAdapterProps> = ({
  buttonText,
  input,
  options,
  labelText,
  disabled = false,
  onChange,
  meta: { error, touched },
}) => {
  const getError = (err: any) => {
    if (typeof err === 'string') return err;
    return err.message ?? '';
  };
  return (
    <FilestackUpload
      options={options}
      buttonText={buttonText}
      labelText={labelText}
      onChange={({ value }) => {
        input.onChange(value);
        onChange?.(value);
      }}
      disabled={disabled}
      errorMessage={getError(touched && error ? error : '')}
    />
  );
};

const mapStateToProps: MapStateToPropsParam<{}, { context: string }> = (
  state,
  ownProps,
) => {
  let formName;
  if (ownProps.context === 'verifications') {
    formName = VERIFICATION_FORM_NAME;
  } else if (ownProps.context === 'candidate-stories') {
    formName = CANDIDATE_STORY_FORM_NAME;
  } else if (ownProps.context === 'instantExceptions') {
    formName = INSTANT_EXCEPTIONS_FORM_NAME;
  } else {
    formName = DISPUTE_FORM_NAME;
  }

  return { isValid: reduxFormIsValid(formName)(state) };
};

export default connect(mapStateToProps)(UploadFieldAdapter);
