import React, { ReactNode } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';

const ListItemStyled = styled(M.ListItem)`
  list-style-type: none;
  .mastodon-icon {
    margin-right: 5px;
  }
`;

interface BulletedItemProps {
  text: string;
  children?: ReactNode;
  arrowIcon?: boolean;
}

export const BulletedItem: React.FC<BulletedItemProps> = ({
  text,
  children = null,
  arrowIcon = false,
}) => {
  return (
    <ListItemStyled>
      <M.Icon
        icon='NextOutline'
        size={20}
        style={{
          fill: arrowIcon ? colors.uiAqua600 : 'none',
        }}
      />
      <span>{i18n.getStr(text)}</span>
      {children}
    </ListItemStyled>
  );
};
