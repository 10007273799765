import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Greeting } from './Greeting';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { TitledContainer } from './TitledContainer';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { BulletedItem } from './BulletedItem';
import { Icon } from './Icon';

interface ReceivedYourInfoProps {
  company?: string;
  firstName?: string;
}

export const ReceivedYourInfo: React.FC<ReceivedYourInfoProps> = ({
  company = '',
  firstName = '',
}) => {
  return (
    <M.Container>
      <Greeting company={company} firstName={firstName} />
      <StyleableContainer type={Type.Success} styledTopBorder>
        <Icon type={Type.Success} />
        <Heading
          textKey='components.InstantExceptions.ReceivedYourInfo.received'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%' }} />
        <TitledContainer titleKey='components.InstantExceptions.ReceivedYourInfo.whatsNext'>
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.ReceivedYourInfo.emailYou'
          />
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.StartedYourBackgroundCheck.logIn'
          >
            <a href='https://candidate.checkr.com'>
              {i18n.getStr('components.Header.title')}
            </a>
            <span>
              {i18n.getStr(
                'components.InstantExceptions.StartedYourBackgroundCheck.toTrack',
              )}
            </span>
          </BulletedItem>
        </TitledContainer>
      </StyleableContainer>
    </M.Container>
  );
};
