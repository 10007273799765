import PropTypes from 'prop-types';
import React, { ReactElement, useState } from 'react';
import { Translate as T } from 'react-redux-i18n';
import {
  arrayRemove as reduxFormArrayRemove,
  Field,
  FormAction,
  formValueSelector,
} from 'redux-form';
import { connect, MapStateToPropsParam } from 'react-redux';
import styled from 'styled-components';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

import { VERIFICATION_FORM_NAME } from '../../constants';
import educationIcon from '../../images/icons/documents/Education.svg';
import { MultilineText, Select } from '../fields';
import * as V from '../../lib/validations';
import UploadButton from '../fields/UploadButton';
import SimpleDocumentList from '../fields/SimpleDocumentList';
import SimplePanel from '../Panels/SimplePanel';
import DisclosureInfoPanel from './DisclosureInfoPanel';

const MAX_CANDIDATE_EXPLANATION_LENGTH = 250;
const NO_DOCUMENT_AVAILABLE = 'no_document_available';
const noDocumentAvailableSelected = (selectedDocType: string) =>
  selectedDocType === NO_DOCUMENT_AVAILABLE;

interface SchoolProps {
  name: string;
  startDate: string;
  endDate: string;
  degree: string;
  city: string;
  state: string;
  major: string;
}

interface EducationDocumentsPanelProps {
  arrayRemove: (form: string, field: string, index: number) => FormAction;
  children: ReactElement;
  documents: File[];
  model: string;
  school: SchoolProps;
  selectedDocType: string;
}

const EducationDocumentsPanel = ({
  arrayRemove,
  children,
  documents,
  model,
  school,
  selectedDocType,
}: EducationDocumentsPanelProps) => {
  const onRemove = (i: number) => arrayRemove(VERIFICATION_FORM_NAME, model, i);

  const SELECT_DOC_TYPE_I18N_KEY = 'options.selectADocType';

  // Doc type values correspond to EducationProof subclass snake-case names in monolith.
  const DEGREE_DOC_TYPE = 'degree';
  const DIPLOMA_DOC_TYPE = 'diploma';
  const EDUCATION_CERTIFICATE_DOC_TYPE = 'education_certificate';
  const TRANSCRIPT_DOC_TYPE = 'transcript';

  const DROPDOWN_UNSELECTED_VALUE = '';

  const DOC_TYPE_DROPDOWN_OPTIONS = [
    {
      value: DROPDOWN_UNSELECTED_VALUE,
      name: SELECT_DOC_TYPE_I18N_KEY,
      i18n: true,
    },
    {
      value: DEGREE_DOC_TYPE,
      name: 'options.educationDocTypes.degree',
      i18n: true,
    },
    {
      value: DIPLOMA_DOC_TYPE,
      name: 'options.educationDocTypes.diploma',
      i18n: true,
    },
    {
      value: EDUCATION_CERTIFICATE_DOC_TYPE,
      name: 'options.educationDocTypes.certificate',
      i18n: true,
    },
    {
      value: TRANSCRIPT_DOC_TYPE,
      name: 'options.educationDocTypes.transcript',
      i18n: true,
    },
    {
      value: NO_DOCUMENT_AVAILABLE,
      name: 'options.noDocumentAvailable',
      i18n: true,
    },
  ];

  const GED_EDUCATION_LEVEL = 'GED';
  const HIGH_SCHOOL_EDUCATION_LEVEL = 'HighSchool';
  const OTHER_EDUCATION_LEVEL = 'degree';

  // maps degree level constant to human readable/displayable value
  // HighSchool => High School, Degree => Education
  const handleDegreeDisplay = (degree: string) => {
    switch (degree) {
      case OTHER_EDUCATION_LEVEL:
        return 'Education';
      case HIGH_SCHOOL_EDUCATION_LEVEL:
        return 'High School';
      default:
        return degree;
    }
  };

  const GED_OR_HIGH_SCHOOL_DOC_TYPE_VALUES = [
    DIPLOMA_DOC_TYPE,
    EDUCATION_CERTIFICATE_DOC_TYPE,
    TRANSCRIPT_DOC_TYPE,
    NO_DOCUMENT_AVAILABLE,
  ];

  const HIGHER_EDUCATION_DOC_TYPE_VALUES = [
    DEGREE_DOC_TYPE,
    EDUCATION_CERTIFICATE_DOC_TYPE,
    TRANSCRIPT_DOC_TYPE,
    NO_DOCUMENT_AVAILABLE,
  ];

  const OTHER_EDUCATION_DOC_TYPE_VALUES = [
    DIPLOMA_DOC_TYPE,
    DEGREE_DOC_TYPE,
    EDUCATION_CERTIFICATE_DOC_TYPE,
    TRANSCRIPT_DOC_TYPE,
    NO_DOCUMENT_AVAILABLE,
  ];

  // Dropdown options of acceptable doc types, depending on education level.
  const docTypeDropdownOptions = (() => {
    // Always include the "Select a doc type" placeholder option.
    const dropdownOptions = DOC_TYPE_DROPDOWN_OPTIONS.filter(
      option => option.value === DROPDOWN_UNSELECTED_VALUE,
    );

    if (
      school.degree === GED_EDUCATION_LEVEL ||
      school.degree === HIGH_SCHOOL_EDUCATION_LEVEL
    ) {
      dropdownOptions.push(
        ...DOC_TYPE_DROPDOWN_OPTIONS.filter(option =>
          GED_OR_HIGH_SCHOOL_DOC_TYPE_VALUES.includes(option.value),
        ),
      );
    }

    // "Other" education level.
    else if (school.degree === OTHER_EDUCATION_LEVEL) {
      dropdownOptions.push(
        ...DOC_TYPE_DROPDOWN_OPTIONS.filter(option =>
          OTHER_EDUCATION_DOC_TYPE_VALUES.includes(option.value),
        ),
      );
    }

    // Higher Education.
    else {
      dropdownOptions.push(
        ...DOC_TYPE_DROPDOWN_OPTIONS.filter(option =>
          HIGHER_EDUCATION_DOC_TYPE_VALUES.includes(option.value),
        ),
      );
    }

    return dropdownOptions;
  })();

  const DEGREE_BULLET_POINT_I18N_KEY_SUFFIX = 'degree';
  const DIPLOMA_BULLET_POINT_I18N_KEY_SUFFIX = 'diploma';
  const CERTIFICATE_BULLET_POINT_I18N_KEY_SUFFIX = 'certificate';
  const TRANSCRIPT_BULLET_POINT_I18N_KEY_SUFFIX = 'transcript';

  // Suffixes of i18n keys of bullet points to render, depending on education level.
  // i.e. the last portion of components.Verification.EducationDocumentsPanel.details.${i18nKeySuffix}
  const bulletPointI18nKeySuffixes = (() => {
    if (
      school.degree === GED_EDUCATION_LEVEL ||
      school.degree === HIGH_SCHOOL_EDUCATION_LEVEL
    ) {
      return [
        DIPLOMA_BULLET_POINT_I18N_KEY_SUFFIX,
        CERTIFICATE_BULLET_POINT_I18N_KEY_SUFFIX,
        TRANSCRIPT_BULLET_POINT_I18N_KEY_SUFFIX,
      ];
    }

    // "Other" education level.
    if (school.degree === OTHER_EDUCATION_LEVEL) {
      return [
        DEGREE_BULLET_POINT_I18N_KEY_SUFFIX,
        DIPLOMA_BULLET_POINT_I18N_KEY_SUFFIX,
        CERTIFICATE_BULLET_POINT_I18N_KEY_SUFFIX,
        TRANSCRIPT_BULLET_POINT_I18N_KEY_SUFFIX,
      ];
    }

    // Higher Education.
    return [
      DEGREE_BULLET_POINT_I18N_KEY_SUFFIX,
      CERTIFICATE_BULLET_POINT_I18N_KEY_SUFFIX,
      TRANSCRIPT_BULLET_POINT_I18N_KEY_SUFFIX,
    ];
  })();

  const StyledBulletPoint = styled.li`
    &:not(:last-child) {
      padding-bottom: 8px !important;
    }
  `;

  const [remainingCharacterCount, setRemainingCharacterCount] = useState(
    MAX_CANDIDATE_EXPLANATION_LENGTH,
  );

  const handleCandidateExplanationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const candidateExplanation = e.target.value;
    setRemainingCharacterCount(
      MAX_CANDIDATE_EXPLANATION_LENGTH - candidateExplanation.length,
    );
  };

  return (
    <>
      <SimplePanel>
        <div className='text-center document-upload-header'>
          <img
            src={educationIcon}
            alt={i18n.getStr(
              'components.Verification.EducationDocumentsPanel.educationProof',
            )}
          />
          <br />
          <br />
          <h3>{school.name}</h3>
          <h4>
            {school.city}
            {school.city && school.state && ', '}
            {school.state}
          </h4>
          <h5 className='text-muted'>
            {handleDegreeDisplay(school.degree)}{' '}
            {school.major && (
              <span>
                <T value='labels.in' /> {school.major}
              </span>
            )}
          </h5>
        </div>
        <div>
          <div className='text-center'>
            <T
              value='components.Verification.EducationDocumentsPanel.text'
              dangerousHTML
              schoolName={school.name}
            />
          </div>
        </div>
      </SimplePanel>
      <SimplePanel>
        <M.Grid>
          <M.GridRow>
            <M.GridCol md={12}>
              <h2>
                <T value='buttons.uploadDocument' />
              </h2>
              <hr />
              <ul>
                {bulletPointI18nKeySuffixes.map(i18nKeySuffix => (
                  <StyledBulletPoint
                    key={`edu-bullet-point-${i18nKeySuffix}`}
                    data-testid='edu-bullet-point'
                  >
                    <T
                      value={`components.Verification.EducationDocumentsPanel.details.${i18nKeySuffix}`}
                      dangerousHTML
                    />
                  </StyledBulletPoint>
                ))}
              </ul>
              {children}
              <M.Grid>
                <M.GridRow>
                  <M.GridCol md={12}>
                    <T
                      value='components.Verification.EducationDocumentsPanel.noDocumentHelpText'
                      dangerousHTML
                    />
                  </M.GridCol>
                </M.GridRow>
              </M.Grid>
              <hr />
              <DisclosureInfoPanel textI18nKey='components.Verification.EducationDocumentsPanel.candidateDisclosurePrompt' />
            </M.GridCol>
          </M.GridRow>

          <M.GridRow className='justify-content-center'>
            <M.GridCol md={4} className='text-md-left'>
              <Field
                type='select'
                name={`${model}__educationDocType`}
                component={Select}
                defaultValue={i18n.getStr(SELECT_DOC_TYPE_I18N_KEY)}
                validate={[V.required]}
                options={docTypeDropdownOptions}
              />
            </M.GridCol>
            <M.GridCol md={8} />
          </M.GridRow>

          {documents.length === 0 && (
            <M.GridRow className='justify-content-center mb-3'>
              <M.GridCol md={4} className='text-md-left'>
                <UploadButton
                  kind='primary'
                  name={model}
                  context={
                    noDocumentAvailableSelected(selectedDocType)
                      ? 'candidate-explanation'
                      : 'verifications'
                  }
                  buttonText='buttons.uploadDocument'
                  disabled={noDocumentAvailableSelected(selectedDocType)}
                  fileOptions={{ maxFiles: 1 }}
                />
              </M.GridCol>
              <M.GridCol md={8} />
            </M.GridRow>
          )}

          {noDocumentAvailableSelected(selectedDocType) && (
            <M.GridRow>
              <M.GridCol md={8}>
                <p className='mb-3'>
                  <T value='components.Verification.EducationDocumentsPanel.noDocumentDisclaimer' />
                </p>
                <Field
                  name='candidate_explanation'
                  component={MultilineText}
                  onChange={handleCandidateExplanationChange}
                  validate={[V.required]}
                  maxLength={MAX_CANDIDATE_EXPLANATION_LENGTH}
                  remainingCharacterCount={remainingCharacterCount}
                />
              </M.GridCol>
            </M.GridRow>
          )}

          {!noDocumentAvailableSelected(selectedDocType) &&
            documents.length > 0 && (
              <M.GridRow className='justify-content-center mb-3'>
                <M.GridCol md={12} className='text-md-left'>
                  <SimpleDocumentList
                    onRemove={onRemove}
                    documents={documents}
                  />
                </M.GridCol>
              </M.GridRow>
            )}
        </M.Grid>
      </SimplePanel>
    </>
  );
};

EducationDocumentsPanel.propTypes = {
  arrayRemove: PropTypes.func,
  children: PropTypes.node,
  documents: PropTypes.array,
  model: PropTypes.string,
  school: PropTypes.object,
  selectedDocType: PropTypes.string,
};

EducationDocumentsPanel.defaultProps = {
  arrayRemove: () => {},
  children: [],
  documents: [],
  model: '',
  school: {},
  selectedDocType: '',
};
interface EducationDocumentsPanelStateProps {
  documents: File[];
  selectedDocType: string;
}

interface EducationDocumentsPanelDispatchProps {
  arrayRemove: (form: string, field: string, index: number) => FormAction;
}

const selector = formValueSelector(VERIFICATION_FORM_NAME);

const mapStateToProps: MapStateToPropsParam<
  EducationDocumentsPanelStateProps,
  { model: string }
> = (state, ownProps) => ({
  documents: selector(state, ownProps.model) || ([] as File[]),
  selectedDocType: selector(
    state,
    `${ownProps.model}__educationDocType`,
  ) as string,
});

const reduxStateConnector = connect<
  EducationDocumentsPanelStateProps,
  EducationDocumentsPanelDispatchProps,
  EducationDocumentsPanelProps
>(mapStateToProps, { arrayRemove: reduxFormArrayRemove })(
  EducationDocumentsPanel,
);

export default i18n.renderTranslation()(reduxStateConnector);
