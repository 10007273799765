import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import { Greeting } from './Greeting';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { TitledContainer } from './TitledContainer';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Icon } from './Icon';
import { Paragraph, Alignment as ParagraphAlignment } from './Paragraph';
import { SecureDisclosure } from './SecureDisclosure';
import { ReduxFileInput } from './ReduxFileInput';
import FooterButtons from './FooterButtons';

interface SsnUploadProps {
  company?: string;
  firstName?: string;
}

export const SsnUpload: React.FC<SsnUploadProps> = ({
  company = '',
  firstName = '',
}) => {
  return (
    <M.Container>
      <Greeting company={company} firstName={firstName} />
      <StyleableContainer type={Type.Info} styledTopBorder>
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.SsnUpload.heading'
          align={HeadingAlignment.Center}
        />
        <Paragraph
          textKey='components.InstantExceptions.SsnUpload.detail'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%' }} />
        <TitledContainer titleKey='components.InstantExceptions.SsnUpload.uploadHeading'>
          <Paragraph
            align={ParagraphAlignment.Left}
            textKey='components.InstantExceptions.SsnUpload.uploadDetail'
          />
        </TitledContainer>
        <h3>PLACEHOLDER - implement filestack?</h3>
        <label htmlFor='ssnDocument'>Upload a file</label>
        <Field name='ssnDocument' component={ReduxFileInput} type='file' />
        <h3>DONE PLACEHOLDER - implement filestack?</h3>
        <Heading
          textKey='components.InstantExceptions.SsnUpload.requirementsHeader'
          align={HeadingAlignment.Left}
        />
        <M.UnorderedList>
          <M.ListItem>
            <Paragraph textKey='components.InstantExceptions.SsnUpload.requirements1' />
          </M.ListItem>
          <M.ListItem>
            <Paragraph textKey='components.InstantExceptions.SsnUpload.requirements2' />
          </M.ListItem>
        </M.UnorderedList>
        <SecureDisclosure />
      </StyleableContainer>
      <FooterButtons>
        <M.Button kind='secondary'>{i18n.getStr('buttons.addLater')}</M.Button>
        <M.Button type='submit'>{i18n.getStr('buttons.submit')}</M.Button>
      </FooterButtons>
    </M.Container>
  );
};
