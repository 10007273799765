import React from 'react';

export const ReduxFileInput = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}: {
  input: any;
  label: any;
  meta: {
    touched: any;
    error: any;
  };
}) => {
  delete input.value; // Avoid "cannot set property value of undefined" error

  return (
    <div>
      <label htmlFor='fhqwgads'>{label}</label>
      <div>
        <input
          id='fhqwgads'
          type='file'
          accept='*/*'
          onChange={e => input.onChange((e?.target?.files || [''])[0])}
          {...custom}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};
