import React, { useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFlagr } from 'api/flagr';
import { Navigate } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { FLAGR_KEYS, INSTANT_EXCEPTIONS_FORM_NAME } from '../../constants';
import MainContainer from '../../components/MainContainer';
import { StartedYourBackgroundCheck as StartedYourBackgroundCheckPage } from './StartedYourBackgroundCheck';
import { ReceivedYourInfo as ReceivedYourInfoPage } from './ReceivedYourInfo';
import { UploadDocumentLater as UploadDocumentLaterPage } from './UploadDocumentLater';
import { SsnReentry as SsnReentryPage } from './SsnReentry';
import { SsnUpload as SsnUploadPage } from './SsnUpload';
import { UploadDeadend as UploadDeadendPage } from './UploadDeadend';
import { DocumentUpload as DocumentUploadPage } from './DocumentUpload';

export enum Page {
  Waiting = 'Waiting',
  StartedYourBackgroundCheck = 'Started Your Background Check',
  ReceivedYourInfo = 'Received your info',
  UploadDocumentLater = 'Upload document later',
  SsnReentry = 'SSN Reentry',
  SsnUpload = 'SSN Upload',
  UploadDeadend = "Upload deadend (can't accept uploads)",
  DocumentUpload = 'Document upload',
}

interface InstantExceptionsFormProps {
  handleSubmit: any;
}

const InstantExceptionsForm: React.FC<InstantExceptionsFormProps> = ({
  handleSubmit,
}) => {
  const [page, setPage] = useState(Page.StartedYourBackgroundCheck);

  const { flag, isFetched: flagIsLoaded } = useFlagr({
    context: {
      locationHost: window.location.host,
    },
    id: FLAGR_KEYS.instantExceptionsEnabled,
    entity: { id: '12345678', type: 'report' },
    fallbackVariant: 'off',
  });
  const { variantKey: instantExceptionsEnabled } = flag;

  if (flagIsLoaded && instantExceptionsEnabled !== 'on') {
    return <Navigate to='/' />;
  }

  let pagePart = null;
  switch (page) {
    case Page.StartedYourBackgroundCheck:
      pagePart = (
        <StartedYourBackgroundCheckPage company='Acme' firstName='Amy' />
      );
      break;
    case Page.ReceivedYourInfo:
      pagePart = <ReceivedYourInfoPage company='Acme' firstName='Amy' />;
      break;
    case Page.UploadDocumentLater:
      pagePart = <UploadDocumentLaterPage company='Acme' firstName='Amy' />;
      break;
    case Page.SsnReentry:
      pagePart = <SsnReentryPage company='Acme' firstName='Amy' />;
      break;
    case Page.SsnUpload:
      pagePart = <SsnUploadPage company='Acme' firstName='Amy' />;
      break;
    case Page.UploadDeadend:
      pagePart = <UploadDeadendPage company='Acme' firstName='Amy' />;
      break;
    case Page.DocumentUpload:
      pagePart = <DocumentUploadPage company='Acme' firstName='Amy' />;
      break;
    default:
      pagePart = <h1>NYI</h1>;
      break;
  }

  // const handleSubmit = (e: any) => {
  //   console.log('submitted form', e);
  // };

  return (
    <MainContainer style={{ background: colors.uiGrey400 }}>
      {flagIsLoaded ? (
        <M.Container style={{ background: colors.uiGrey50 }}>
          <form onSubmit={handleSubmit}>
            <M.Select
              hideLabel
              id='page'
              defaultValue={page}
              onChange={(e: {
                target: { value: React.SetStateAction<Page> };
              }) => setPage(e.target.value)}
            >
              {Object.values(Page).map(key => (
                <M.Select.Item key={key} value={key} text={key} />
              ))}
            </M.Select>
            <p>selected page {page}</p>
            {pagePart}
          </form>
        </M.Container>
      ) : null}
    </MainContainer>
  );
};

const ContactForm = reduxForm({
  form: INSTANT_EXCEPTIONS_FORM_NAME,
})(InstantExceptionsForm);

class InstantExceptions extends React.Component {
  submit = (values: any) => {
    // print the form values to the console
    // TODO: handle the form inputs
    // eslint-disable-next-line no-console
    console.log(values);
  };

  render() {
    return <ContactForm onSubmit={this.submit} />;
  }
}

export default InstantExceptions;
